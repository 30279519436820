import React, {useState,useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import Role1 from '../Images/Role1.jpeg';
import Role2 from '../Images/Role2.jpeg';
import Role3 from '../Images/Role3.jpg';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import "./Offer.css";
import styled from 'styled-components';
import ArrowBackIosIcon from '../Images/ArrowBack.png';
import { useNavigate } from 'react-router-dom';
import ResponsiveNav from './ResponsiveNav';
import { NavItem, NavLink } from 'reactstrap';
import MenuIcon from '../Images/NavIcon.png';
import { useNavGrid } from '../Context/NavGridContext';
import GridLinks from "./GridLinks";

function ScrollToTopOnMount() {
    const navigate = useNavigate();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [navigate]);
  
    return null;
  }

const Icon = styled.img`
  position: absolute;
  display: inline-block;
  top: 23px;
  left: 30px;
 height:43px;
 width:'25px';

  cursor: pointer;
  z-index: 3;

    @media (max-width: 767px) {
    font-size: 36px!important;
   top: 23px;
     left: 10px;
  }
`;
const Text = styled.p`
 position: absolute;
  font-size: 21px;
  margin-left: 14px;
   top: 29px;
  left: 46px;
  cursor: pointer;

    @media (max-width: 767px) {
    font-size: 16px;
 top: 32px;
     left: 20px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  transition: color 0.3s;

  &:hover {
    color: gray;
  }
`;

const StyledParagraph = styled.p`
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 24px;

    /* Media query for smaller screens */
  @media (max-width: 767px) {
    font-size: 18px;
  }

  /* Media query for medium screens */
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 16px;
  }

  /* Media query for large screens */
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 20px;
  }


`;

const StyledLine = styled.div`
  width: 95%;
  height: 1px;
  background-color: rgb(100, 149, 237);
  margin: 8px auto;

`;


const StyledTitle = styled.p`
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 24px;


  /* Media query for smaller screens */
  @media (max-width: 767px) {
    font-size: 18px;
  }

  /* Media query for medium screens */
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 20px;
  }

  /* Media query for large screens */
  @media (min-width: 992px) {
    font-size: 22px;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const GrayscaleImage = styled.img`
  filter: ${props => (props.isHovered ? 'none' : 'grayscale(100%)')};
  transition: filter 0.3s ease-in-out;
`;

const OfferComponent = () => {
    const [hoveredImages, setHoveredImages] = useState({});

    const handleMouseEnter = id => {
      setHoveredImages(prevState => ({
        ...prevState,
        [id]: true
      }));
    };
  
    const handleMouseLeave = id => {
      setHoveredImages(prevState => ({
        ...prevState,
        [id]: false
      }));
    };
    const navigate = useNavigate();
    return (
        <>
            <ResponsiveNav />
            <div style={{ flex: 1, minHeight: 'calc(100vh - 160px)' }} className=" d-flex flex-column align-items-center justify-content-center" >
                <Container className="text-center d-flex align-items-center justify-content-center ">
                    <Row className="col-12 d-flex flex-column align-items-center justify-content-center ">

                        <Col xs={12} md={11} lg={11} xl={10}>
                            <p style={{ fontSize: '24px' }}>
                                OUR<b> ROLES</b>
                            </p>
                            <p style={{ fontSize: '17px' }}><i>With a commitment to excellence, we specialize in providing top-tier virtual <b>professionals for the following</b> roles on
                                outsourced basis that would drive your business forward…</i>
                            </p>

                        </Col> </Row>
                </Container>
                <Container className="text-center d-flex align-items-center justify-content-center mt-2 mb-5 mx-1">
                    <Row className="col-12 d-flex align-items-center justify-content-center">



                        <Col xs={12} sm={12} md={4} lg={4}  >
                            <StyledLink to="/one-role" >
                                <ImageContainer>
                                    <GrayscaleImage
                                        src={Role1}
                                        alt="Role1"
                                        className="img-fluid shadow-lg"
                                        isHovered={hoveredImages['Role1']}
                                        onMouseEnter={() => handleMouseEnter('Role1')}
                                        onMouseLeave={() => handleMouseLeave('Role1')}
                                  
                                    />
                                </ImageContainer>
                  
                                <StyledParagraph>
                                    MARKETING <b>ASSOCIATE</b>
                                    <StyledLine />
                                </StyledParagraph>
                            </StyledLink>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4}>
                            <StyledLink to="/second-role" >
                            <ImageContainer>
                                    <GrayscaleImage
                                        src={Role2}
                                        alt="Role2"
                                        className="img-fluid shadow-lg"
                                        isHovered={hoveredImages['Role2']}
                                        onMouseEnter={() => handleMouseEnter('Role2')}
                                        onMouseLeave={() => handleMouseLeave('Role2')}
                                      
                                    />
                                </ImageContainer>
                                <StyledParagraph>
                                    SALES <b>REPRESENTATIVE</b>
                                    <StyledLine />
                                </StyledParagraph>
                            </StyledLink>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <StyledLink to="/third-role">
                            <ImageContainer>
                                    <GrayscaleImage
                                        src={Role3}
                                        alt="Role3"
                                        className="img-fluid shadow-lg"
                                        isHovered={hoveredImages['Role3']}
                                        onMouseEnter={() => handleMouseEnter('Role3')}
                                        onMouseLeave={() => handleMouseLeave('Role3')}
                                        
                                    />
                                </ImageContainer>
                                <StyledParagraph>
                                    ACCOUNT <b>MANAGER</b>
                                    <StyledLine />
                                </StyledParagraph>
                            </StyledLink>
                        </Col>


                    </Row>
                </Container>
            </div>
        </>
    );

};



const Careers = () => {
    const navigate = useNavigate();
    const [hoveredImages, setHoveredImages] = useState({});

    const handleMouseEnter = id => {
      setHoveredImages(prevState => ({
        ...prevState,
        [id]: true
      }));
    };
  
    const handleMouseLeave = id => {
      setHoveredImages(prevState => ({
        ...prevState,
        [id]: false
      }));
    };

    const handleOpenPDF = (path) => {

        const pdfPath = path;

        window.open(pdfPath, '_blank');
    };
    return (
        <div style={{ flex: 1, minHeight: 'calc(100vh - 50px)' }}>
            <div style={{ border: "8px solid gray", marginBottom: "25px", marginTop: "25px", marginRight: "30px", marginLeft: "30px", }}>
                <Container className="text-center d-flex align-items-center justify-content-center">
                    <Row className="col-12 d-flex flex-row align-items-center justify-content-center">


                        <div className=" d-flex align-items-center justify-content-center ">
                            <Icon src={ArrowBackIosIcon} style={{ top: '92px', left: '104px' }}
                                onClick={() => { navigate('/'); }} />

                        </div>


                        <Col className="text-center my-3 " xs={12} lg={8}>

                            <p style={{ fontSize: '24px' }} ><b>  CAREERS</b>

                            </p>


                        </Col>
                    </Row>
                </Container>
                <Container className="text-center d-flex align-items-center justify-content-center mb-5">
                    <Row className="col-12 d-flex align-items-center justify-content-center">



                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                            <StyledLink to="https://forms.gle/ocRcyHnF64Fr77Jj7" target="_blank">
                            <ImageContainer>
                                    <GrayscaleImage
                                        src={Role1}
                                        alt="Role1"
                                        className="img-fluid shadow-lg"
                                        isHovered={hoveredImages['Role1']}
                                        onMouseEnter={() => handleMouseEnter('Role1')}
                                        onMouseLeave={() => handleMouseLeave('Role1')}
                                     
                                    />
                                </ImageContainer>
                                <StyledParagraph>
                                    MARKETING <b>ASSOCIATE</b>
                                    <StyledLine />
                                </StyledParagraph>
                                <div style={{ marginTop: '-8px' }}>
                                    <Link onClick={() => { handleOpenPDF(`${process.env.PUBLIC_URL}/ToR - Marketing Associate.pdf`); }} target="_blank" style={{ textDecoration: 'none' }} ><StyledParagraph style={{ textAlign: 'center', textDecoration: 'none', color: 'black' }}>Terms of Reference
                                    </StyledParagraph></Link></div>

                            </StyledLink>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                            <StyledLink to="https://forms.gle/ocRcyHnF64Fr77Jj7" target="_blank">
                            <ImageContainer>
                                    <GrayscaleImage
                                        src={Role2}
                                        alt="Role2"
                                        className="img-fluid shadow-lg"
                                        isHovered={hoveredImages['Role2']}
                                        onMouseEnter={() => handleMouseEnter('Role2')}
                                        onMouseLeave={() => handleMouseLeave('Role2')}
                                     
                                    />
                                </ImageContainer>

                                <StyledParagraph>
                                    <b>SALES </b>REPRESENTATIVE
                                    <StyledLine />
                                </StyledParagraph>
                                <div style={{ marginTop: '-8px' }}>
                                    <Link onClick={() => { handleOpenPDF(`${process.env.PUBLIC_URL}/ToR - Sales Development Rep.pdf`); }} target="_blank" style={{ textDecoration: 'none' }} ><StyledParagraph style={{ textAlign: 'center', textDecoration: 'none', color: 'black' }}>Terms of Reference
                                    </StyledParagraph></Link></div>

                            </StyledLink>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4} xl={4} >
                            <StyledLink to="https://forms.gle/ocRcyHnF64Fr77Jj7" target="_blank">
                            <ImageContainer>
                                    <GrayscaleImage
                                        src={Role3}
                                        alt="Role3"
                                        className="img-fluid shadow-lg"
                                        isHovered={hoveredImages['Role3']}
                                        onMouseEnter={() => handleMouseEnter('Role3')}
                                        onMouseLeave={() => handleMouseLeave('Role3')}
                                     
                                    />
                                </ImageContainer>
                                <StyledParagraph>
                                    <b>ACCOUNT </b>MANAGER
                                    <StyledLine />
                                </StyledParagraph>
                                <div style={{ marginTop: '-8px' }}>
                                    <Link onClick={() => { handleOpenPDF(`${process.env.PUBLIC_URL}/ToR - Account Manager.pdf`); }} target="_blank" style={{ textDecoration: 'none' }} > <StyledParagraph style={{ textAlign: 'center', textDecoration: 'none', color: 'black' }}>Terms of Reference
                                    </StyledParagraph></Link></div>

                            </StyledLink>
                        </Col>


                    </Row>
                </Container>

            </div>
            <div style={{ textAlign: 'right', marginTop: "-16px", }} >
                <a href="https://forms.gle/ocRcyHnF64Fr77Jj7" target="_blank" style={{ textDecoration: 'none' }} ><p className=" p-2 me-0 me-md-5" style={{ textAlign: 'right', color: "green", textDecoration: 'none', fontSize: "20px" }}><i><b>{"Apply here >>"}
                </b></i></p></a></div>
        </div>
    );

};


const OneRole = () => {
    const navigate = useNavigate();
    const { showGrid } = useNavGrid();
    return (
        <>
          
          <ScrollToTopOnMount />
            <header>
                <div className=" d-flex align-items-center justify-content-center ">
                    <Icon src={ArrowBackIosIcon}
                        onClick={() => { navigate('/offer'); }} />

                </div>

                <Col xs={12} md={12} className="d-flex  align-items-end justify-content-end " style={{ marginTop: '18px' }} >
                    <ul className="navbar-nav d-flex flex-row ">
                        <NavItem >
                            <NavLink className="text-dark m-0 p-0" onClick={showGrid} ><img src={MenuIcon} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '54px' }} /></NavLink>
                        </NavItem>
                    </ul>
                </Col>
                <div style={{ marginRight: '41px' }}>{<GridLinks />}</div>

            </header>

            <Row className=" d-flex  align-items-center justify-content-center mt-5">

                <Col className="text-center my-3" xs="auto" lg={5}>
                    <Card className="my-5 mx-0 d-inline-block role  " style={{
                        boxShadow: '0 4px 8px rgba(0,0,0,0.5)', border: '1px solid black'
                    }}>
                        <Card.Title><h5 style={{ backgroundColor: 'black', color: 'white', padding: '10px', fontSize: '24px' }}>JOB DESCRIPTION</h5></Card.Title>
                        <Card.Body>
                            <div style={{ marginBottom: '40px' }}>
                                <p className=" mb-0" style={{ fontSize: '16px' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '43px', color: 'rgb( 221, 159, 0)' }}>$15</span> USD
                                    <div style={{ width: '50%', height: '1px', backgroundColor: '#000', margin: '2px auto' }}></div>
                                    <span style={{ fontSize: '16px' }}>per hour</span>
                                </p>
                                <ul className="list-group list-unstyled custom-list">
                                    <li className="list-group-item"><b>Launch Key Marketing Campaigns </b>
                                        <ul className="list-group list-unstyled sub-list ">
                                            <li >Paid Ads </li>
                                            <li>Cold Email Outreach </li>
                                            <li >Lead Generation </li>
                                        </ul></li>
                                    <li className="list-group-item"><b>Social Media Management </b>
                                        <ul className="list-group list-unstyled sub-list">
                                            <li >Content Creation </li>
                                            <li >Content Management </li>
                                            <li >Social Media Outreach </li>
                                            <li >Content Marketing  </li>
                                        </ul></li>
                                    <li className="list-group-item"><b>Setting up & managing your CRM </b>
                                        <ul className="list-group list-unstyled sub-list">
                                            <li>Automation & Workflows </li>
                                            <li >Landing Pages & Forms</li>

                                        </ul></li>
                                </ul>
                            </div>

                        </Card.Body>
                    </Card>

                </Col>


                <Col className=" mb-5 mb-lg-0  ms-none ms-md-2"
                    xs={12} sm={12} md={4} lg={4}>
                    <img src={Role1} alt="Role1" className="img-fluid shadow-lg mt-3"></img>
                    <p className="mt-3 mb-0" style={{ fontSize: '24px', textAlign: 'center' }}>
                        <b >MARKETING</b> ASSOCIATE
                        <div style={{ width: '95%', height: '0.5px', backgroundColor: 'rgb(100, 149, 237)', margin: '8px auto' }}></div>
                    </p>
                    <Row>
                        <Link to="https://wa.me/15517990700" target="_blank" style={{ textDecoration: 'none' }}>
                            <button
                                color="dark"
                                className="px-4 py-1 mt-3"
                                style={{
                                    borderRadius: '60px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'black',
                                    color: "white",
                                    margin: 'auto',

                                }}
                            >

                                <span style={{ verticalAlign: 'middle', fontSize: '24px' }}>Get Started</span>
                            </button>
                        </Link>
                    </Row>
                </Col>

            </Row>


        </>
    );

};


const SecondRole = () => {
    const navigate = useNavigate();
    const { showGrid } = useNavGrid();
    return (
        <>
 <ScrollToTopOnMount />
            <header>
                <div className=" d-flex align-items-center justify-content-center ">
                    <Icon src={ArrowBackIosIcon}
                        onClick={() => { navigate('/offer'); }} />

                </div>

                <Col xs={12} md={12} className="d-flex  align-items-end justify-content-end " style={{ marginTop: '18px' }} >
                    <ul className="navbar-nav d-flex flex-row ">
                        <NavItem >
                            <NavLink className="text-dark m-0 p-0" onClick={showGrid} ><img src={MenuIcon} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '54px' }} /></NavLink>
                        </NavItem>
                    </ul>
                </Col>
                <div style={{ marginRight: '41px' }}>{<GridLinks />}</div>

            </header>

            <Row className=" d-flex  align-items-center justify-content-center mt-5">

                <Col className="text-center my-3" xs="auto" lg={5}>
                    <Card className="my-5 mx-0 d-inline-block role  " style={{
                        boxShadow: '0 4px 8px rgba(0,0,0,0.5)', border: '1px solid black'
                    }}>
                        <Card.Title><h5 style={{ backgroundColor: 'black', color: 'white', padding: '10px', fontSize: '24px' }}>JOB DESCRIPTION</h5></Card.Title>
                        <Card.Body>
                            <div style={{ marginBottom: '40px' }}>
                                <p className=" mb-0" style={{ fontSize: '16px' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '43px', color: 'rgb( 221, 159, 0)' }}>$15</span> USD
                                    <div style={{ width: '50%', height: '1px', backgroundColor: '#000', margin: '2px auto' }}></div>
                                    <span >per hour</span>
                                </p>
                                <ul className="list-group list-unstyled custom-list">
                                    <li className="list-group-item"><b>Setting appointments</b>
                                        <ul className="list-group list-unstyled sub-list ">
                                            <li >100 outbound calls per day</li>
                                            <li>Follow-up with leads </li>
                                            <li >Scheduling demos</li>
                                        </ul></li>
                                    <li className="list-group-item"><b>Technical skills </b>
                                        <ul className="list-group list-unstyled sub-list">
                                            <li >CRM management </li>
                                            <li >Organize calendars </li>
                                            <li >Receiving calls </li>

                                        </ul></li>
                                    <li className="list-group-item"><b>Soft skills </b>
                                        <ul className="list-group list-unstyled sub-list">
                                            <li>Communication skills </li>
                                            <li >Interpersonal skills</li>

                                        </ul></li>
                                </ul>
                            </div>

                        </Card.Body>
                    </Card>

                </Col>


                <Col className=" mb-5 mb-lg-0  ms-none ms-md-3"
                    xs={12} sm={12} md={4} lg={4}>
                    <img src={Role2} alt="Role2" className="img-fluid shadow-lg mt-3"></img>
                    <p className="mt-3 mb-0" style={{ fontSize: '24px', textAlign: 'center' }}>
                        <b>SALES</b> REPRESENTATIVE
                        <div style={{ width: '95%', height: '1px', backgroundColor: 'rgb(100, 149, 237)', margin: '8px auto' }}></div>
                    </p>
                    <Row>
                        <Link to="https://wa.me/15517990700" target="_blank" style={{ textDecoration: 'none' }}>
                            <button
                                color="dark"
                                className="px-4 py-1 mt-3"
                                style={{
                                    borderRadius: '60px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'black',
                                    color: "white",
                                    margin: 'auto',

                                }}
                            >

                                <span style={{ verticalAlign: 'middle', fontSize: '24px' }}>Get Started</span>
                            </button>
                        </Link>
                    </Row>
                </Col>

            </Row>


        </>
    );

};

const ThirdRole = () => {
    const navigate = useNavigate();
    const { showGrid } = useNavGrid();
    return (
        <>
 <ScrollToTopOnMount />
            <header>
                <div className=" d-flex align-items-center justify-content-center ">
                    <Icon src={ArrowBackIosIcon}
                        onClick={() => { navigate('/offer'); }} />

                </div>

                <Col xs={12} md={12} className="d-flex  align-items-end justify-content-end " style={{ marginTop: '18px' }} >
                    <ul className="navbar-nav d-flex flex-row ">
                        <NavItem >
                            <NavLink className="text-dark m-0 p-0" onClick={showGrid} ><img src={MenuIcon} alt="Logo" style={{ width: '50px', height: '50px', marginRight: '54px' }} /></NavLink>
                        </NavItem>
                    </ul>
                </Col>
                <div style={{ marginRight: '41px' }}>{<GridLinks />}</div>

            </header>
            <Row className=" d-flex  align-items-center justify-content-center mt-5">

                <Col className="text-center my-3" xs={12} lg={5}>
                    <Card className="my-5 mx-0 d-inline-block role  " style={{
                        boxShadow: '0 4px 8px rgba(0,0,0,0.5)', border: '1px solid black'
                    }}>
                        <Card.Title><h5 style={{ backgroundColor: 'black', color: 'white', padding: '10px', fontSize: '24px' }}>JOB DESCRIPTION</h5></Card.Title>
                        <Card.Body>
                            <div style={{ marginBottom: '40px' }}>
                                <p className=" mb-0" style={{ fontSize: '16px' }}>
                                    <span style={{ fontWeight: 'bold', fontSize: '43px', color: 'rgb( 221, 159, 0)' }}>$15</span> USD
                                    <div style={{ width: '50%', height: '1px', backgroundColor: '#000', margin: '2px auto' }}></div>
                                    <span >per hour</span>
                                </p>
                                <ul className="list-group list-unstyled custom-list">
                                    <li className="list-group-item"><b>Client Value Preposition </b>
                                        <ul className="list-group list-unstyled sub-list ">
                                            <li >Focus on client satisfaction </li>
                                            <li>Client relationship </li>
                                            <li >Performance discussions</li>
                                        </ul></li>
                                    <li className="list-group-item"><b>Technical skills </b>
                                        <ul className="list-group list-unstyled sub-list">
                                            <li >Enhancing profitability </li>
                                            <li >Strategic counsel </li>

                                        </ul></li>
                                    <li className="list-group-item"><b>Soft skills</b>
                                        <ul className="list-group list-unstyled sub-list">
                                            <li>Communication skills</li>
                                            <li >Interpersonal skills</li>

                                        </ul></li>
                                </ul>
                            </div>

                        </Card.Body>
                    </Card>

                </Col>


                <Col className=" mb-5 mb-lg-0 ms-none ms-md-3"
                    xs={12} sm={12} md={4} lg={4}>
                    <img src={Role3} alt="Role3" className="img-fluid shadow-lg mt-3"></img>
                    <p className="mt-3 mb-0" style={{ fontSize: '24px', textAlign: 'center' }}>
                        <b>ACCOUNT</b> MANAGER
                        <div style={{ width: '95%', height: '0.5px', backgroundColor: 'rgb(100, 149, 237)', margin: '8px auto' }}></div>
                    </p>
                    <Row>
                        <Link to="https://wa.me/15517990700" target="_blank" style={{ textDecoration: 'none' }}>
                            <button
                                color="dark"
                                className="px-4 py-1 mt-3"
                                style={{
                                    borderRadius: '60px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'black',
                                    color: "white",
                                    margin: 'auto',

                                }}
                            >

                                <span style={{ verticalAlign: 'middle', fontSize: '24px' }}>Get Started</span>
                            </button>
                        </Link>
                    </Row>
                </Col>

            </Row>


        </>
    );

};

export { OneRole };
export { Careers };
export default OfferComponent;
export { SecondRole };
export { ThirdRole };