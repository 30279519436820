import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { Button} from 'reactstrap';
import home from '../Images/Homepage.png';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import ResponsiveNav from './ResponsiveNav';


const Home = () => {

    return (
        <>
           <ResponsiveNav />
    
          
                
            <div style={{ flex: 1, minHeight: 'calc(100vh - 160px)' }}>
                <Row className="d-flex flex-column justify-content-center align-items-center mt-0">
                    <Col xs={12} md={4} lg={4} xl={3} className='p-0 mt-0'>
                        <img src={home} alt="home" className="img-fluid " style={{marginTop:'-6px'}} />
                    </Col>
                    <Col xs={12} md={10} lg={9} xl={7}>
                        <div className="text-center ">
                            <p style={{ fontSize: '28px' }}><i>If a <b style={{ fontSize: '30px' }}>Digital Marketing Associate</b> significantly <span style=
                                {{ color: 'rgb( 221, 159, 0)', fontWeight: 'bold' }}>increases your sales,</span> would it cost you any expense?</i></p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <NavLink tag={Link} className="text-dark text-center d-flex flex-column justify-content-center mt-2 mb-5" to="https://wa.me/15517990700" target='_blank'>
                        <p style={{marginBottom:'-3px'} }>Make a free</p>
                            <Button
                                color="dark"
                                className="px-4 py-1"
                                style={{
                                    borderRadius: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '5px 10px 5px 10px',
                                    margin: 'auto',
                                  

                                   
                                }}
                              
                            >
                              
                                <span style={{ verticalAlign: 'middle', fontSize: '22px', paddingInlineEnd: '5px' }}>Discovery Call</span>
                            </Button>
                        </NavLink>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Home;